import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Image from 'next/future/image';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'usehooks-ts';

export type MediaT = {
  type: string;
  src: string;
  alt: string;
};

interface Props {
  source: {
    mobile: MediaT;
    tablet: MediaT;
    desktop: MediaT;
  };
  className?: string;
}

const Media: React.FC<Props> = ({ source, className }) => {
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1279px)');
  const isDesktop = useMediaQuery('(min-width: 1280px)');

  return useMemo(() => {
    const { type, src, alt } = isTablet
      ? source.tablet
      : isDesktop
      ? source.desktop
      : source.mobile;

    if (type === 'image') {
      return (
        <Image
          src={src}
          alt={alt}
          width={0}
          height={0}
          sizes="100vw"
          priority
          className={clsx('w-[100%] h-full object-cover', className)}
        />
      );
    }

    return (
      <video src={src} className={clsx('w-full h-full', className)} autoPlay />
    );
  }, [isTablet, isDesktop]);
};

export default dynamic(() => Promise.resolve(Media), { ssr: false });
