import React from 'react';
import Carousel from 'react-multi-carousel';
import { IHomeProduct } from '../../models';
import usePrice from '../../hooks/usePrice';
import { useTranslation } from 'react-i18next';
import LinkTo from '../LinkTo';
import localizedRoute from '../../utils/localizedRoute';
import Spinner from '../Spinner';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    partialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 20
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 20
  }
};

const MycarouselProducts = ({
  products,
  loading
}: {
  products: IHomeProduct[] | undefined;
  loading?: boolean;
}) => {
  const [formatPrice] = usePrice();
  const { t } = useTranslation();

  return loading ? (
    <Spinner />
  ) : products?.length ? (
    <>
      <h2
        className="text-5xl md:text-40 leading-tight font-sans mb-2 text-black"
        dangerouslySetInnerHTML={{ __html: t('SECOND_CAROUSEL_TITLE') }}
      />
      <p
        className="text-md uppercase font-sans mb-6 opacity-60"
        dangerouslySetInnerHTML={{ __html: t('SECOND_CAROUSEL_DESCRIPTION') }}
      />
      <Carousel
        ssr
        partialVisible
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        containerClass="carousel-container my-carousel"
        infinite={true}
        arrows={true}
      >
        {products.map(product => {
          return (
            <div className="mr-3" key={product.title}>
              <div
                className="bg-no-repeat h-96 w-full p-4 must-have-image"
                style={{
                  background: `linear-gradient(to bottom, rgba(0, 20, 26, 0.1), rgba(0, 20, 26, 0.25)), url(" ${product.image}")`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              />
              <div className="w-full pt-5 flex flex-col md:flex-row must-have-title">
                <LinkTo
                  href={localizedRoute(product.link)}
                  className="font-bold flex-grow"
                >
                  <span aria-hidden="true" className="absolute inset-0" />
                  {product.title}
                </LinkTo>
                <p className="whitespace-nowrap opacity-60 pl-0 md:pl-5 mt-2 md:mt-0">
                  {formatPrice(product.price)}
                </p>
              </div>
            </div>
          );
        })}
      </Carousel>
    </>
  ) : null;
};

export default MycarouselProducts;
