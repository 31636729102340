import React from 'react';
import { IHomeBanner } from '../models';
import Link from 'next/link';
import localizedRoute from '../utils/localizedRoute';
import parse from 'html-react-parser';
import logoJeepXtream from '../public/img/logo-jeep-xtreme.png';
import StyledImage from './StyledImage';

const HomeBanner = ({ banner }: { banner: IHomeBanner }) => {
  if (!banner) {
    return null;
  }

  return (
    <div
      className="grid grid-cols-1 2xl:grid-cols-2 gap-4 text-white px-12 py-28 sm:py-40 overflow-hidden"
      style={{
        background: `linear-gradient(to bottom, rgba(0, 20, 26, 0.1), rgba(0, 20, 26, 0.25)), url(${
          banner.image || '/img/xtreme-banner.jpg'
        })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%'
      }}
    >
      {banner.text_align && banner.text_align === 'left' && <div></div>}
      <div
        className={
          banner.text_align && banner.text_align === 'left'
            ? 'text-left md:text-left'
            : ''
        }
      >
        {banner.extreme ? (
          <div
            className={
              banner.text_align === 'left' ? '' : 'text-left' + ' mb-4'
            }
          >
            <StyledImage
              src={logoJeepXtream}
              alt="Xtreme badge"
              className="mb-6"
            />
          </div>
        ) : (
          <h1 className="font-sans text-4xl xl:text-6xl 2xl:text-8xl uppercase mb-6">
            {parse(banner.title)}
          </h1>
        )}
        <p className="text-md uppercase font-sans mb-6 text-white">
          {parse(banner.text)}
        </p>
        <Link href={localizedRoute(banner.btn_link)}>
          <a className="inline-block bg-orange text-black font-normal font-sans px-4 py-5 rounded-sm uppercase cursor-pointer w-70 sm:w-56 text-center">
            {banner.btn_text}
          </a>
        </Link>
      </div>
      {(!banner.text_align ||
        (banner.text_align && banner.text_align !== 'right')) && <div></div>}
    </div>
  );
};

export default HomeBanner;
